.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 50px;
    padding: 30px;
    margin: 30px;
}

.skill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.skill-name {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
}

.skill-level {
    background-color: #EEEEEE;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 10px;
    width: 70%;
}

.skill-level-bar {
    background-color: #43A047;
    height: 20px;
    border-radius: 10px;
    width: 80%;
    transition: all 0.5s ease-in-out;
}

.skill-level-bar span {
    color: #FFFFFF;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    position: relative;
    top: -28px;
    background-color: #43A047;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(-10px);
}

.skill-level:hover .skill-level-bar {
    width: 100%;
    background-color: #66BB6A;
}

.skill-level:hover .skill-level-bar span {
    opacity: 1;
    transform: translateY(-20px);
}