.slide-up {
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
}

.slide-up.visible {
    transform: translateY(0);
}

.fullSizeImage {
    width: 100%;
    height: 100%;
}

.homePageCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f2f2f2;
}

.carousel-container {
    width: 100%; /* Ensure full width of parent container */
    height: 100%; /* Ensure full height of parent container */
}

.carousel-item-container {
    height: 100%;
    width: 100%;
}

.carousel-inner {
    height: 100%;
}