.App {
  text-align: center;
  display: flex;
  box-sizing: content-box;
  flex-flow: column;
  height: 100%;
}

.mainContent {
  flex-grow: 1;
  overflow-y: auto;
  /* padding-top: 50px;
  padding-bottom: 150px; */ 
}

body > #root > div {
  height: 100vh;
  overflow: hidden;
}