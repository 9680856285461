.WorkSizing {
    margin: 15px;
    height: 100%;
}

.TabStyle {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 20px;
}

.tab .nav-link {
    color: black !important
}

.tab .nav-link:hover {
    color: black !important;
}

.tab .nav-item {
    margin-bottom: 0px;
}
