.projectCard {
    margin: 30px;
    padding: 30px;
    height: 150px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    cursor: pointer;
}

.projectDesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    border-bottom: none;
    background-color: #f8f9fa;
    border-radius: 8px 8px 0px 0px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .card-text {
    font-size: 16px;
    line-height: 1.5;
    color: #6c757d;
  }
  
  .card-link {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
  }
  
  .card-link:hover {
    text-decoration: underline;
  }
  
  .card-footer {
    background-color: #f8f9fa;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 10px 20px;
  }
  