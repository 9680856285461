.under-construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f2f2f2;
  }
  
  .under-construction img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .under-construction h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .under-construction p {
    font-size: 1.2rem;
    text-align: center;
    max-width: 500px;
    margin-bottom: 0;
  }
  